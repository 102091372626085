<template>
    <v-container fluid>
        <v-row>
            <v-col><h1>Invoice</h1></v-col>
            <v-col style="text-align: right;"><v-btn v-if="!showForm" color="primary" @click="showFormMethod">Add Invoice</v-btn></v-col>
        </v-row>
        <v-simple-table v-if="!showForm">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Inv No.</th>
                        <th class="text-left">Inv Date</th>
                        <th class="text-left">Phone</th>
                        <th class="text-left">Description</th>
                        <th class="text-left">Total Price</th>
                        <th class="text-left">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="invoice in filteredInvoices" :key="invoice.id">
                        <td>{{ invoice.invoice_number }}</td>
                        <td>
                            <div>{{ formatDate(invoice.updated_at) }}</div>
                            <div>{{ formatTime(invoice.updated_at) }}</div>
                        </td>
                        <td>{{ invoice.phone }}</td>
                        <td>
                            <ul>
                                <li v-for="(description, index) in invoice.item_description" :key="index" style="width: 300px;" class="invoice-item">
                                    {{ description }} - {{ invoice.item_price[index] }}
                                </li>
                            </ul>
                        </td>
                        <td>{{ invoice.total }}</td>
                        <td>
                            <v-btn small color="default" @click="reprint(invoice)">Reprint</v-btn>
                            <v-btn small color="primary" @click="editItem(invoice)">Edit</v-btn>
                            <!-- <v-btn small color="error" @click="deleteItem(invoice)">Delete</v-btn> -->
                            <!-- <v-btn small color="success" @click="markAsCompleted(invoice)">Completed</v-btn> -->
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-card v-if="showForm && !isEditMode">
            <v-card-title class="headline">Add Invoice</v-card-title>
            <v-card-text>
                <v-form ref="form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                        v-model="invoice.invoiceTo"
                        :items="['Cash', 'Custom']"
                        label="To"
                        outlined
                        dense
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                    <v-text-field
                        v-if="invoice.invoiceTo === 'Custom'"
                        v-model="customInvoiceTo"
                        label="Custom To"
                        outlined
                        dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                        v-model="invoice.invoice_type"
                        :items="['1', '2', '3']"
                        label="Invoice Type"
                        outlined
                        dense
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                    <v-text-field v-model="invoice.phone" label="Phone No" outlined dense></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-for="(item, index) in invoice.invoices" :key="index">
                <v-col cols="12">
                    <v-textarea v-model="item.description" label="Invoice Detail" outlined dense></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense></v-text-field>
                </v-col>
                </v-row>
                <v-btn text color="primary" @click="addDetailInvoice" v-if="invoice.invoices.length < 3">
                Add Invoice Item
                </v-btn>
                <v-spacer></v-spacer>
                <div>Total Price (RM): {{ calculateTotalPrice() }}</div>
                </v-form>
            </v-card-text>
            <v-btn color="primary" @click="showForm = false">Cansel</v-btn>
            <v-btn color="success" @click="submitNewInvoice">New Invoice</v-btn>
            <!-- <v-btn color="success" @click="submitInvoiceAndPrint">New Invoice & Print</v-btn> -->
        </v-card>

        <v-card v-if="showForm && isEditMode">
            <v-card-title class="headline">Edit Invoice</v-card-title>
            <v-card-text>
                <v-form ref="form">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                        v-model="invoice.invoiceTo"
                        :items="['Cash', 'Custom']"
                        label="To"
                        outlined
                        dense
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-if="invoice.invoiceTo === 'Custom'"
                            v-model="customInvoiceTo"
                            label="Custom To"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-select
                        v-model="invoice.invoice_type"
                        :items="['1', '2', '3']"
                        label="Invoice Type"
                        outlined
                        dense
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                    <v-text-field v-model="invoice.phone" label="Phone No" outlined dense></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-for="(item, index) in invoice.invoices" :key="index">
                <v-col cols="12">
                    <v-textarea v-model="item.description" label="Invoice Detail" outlined dense></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense></v-text-field>
                </v-col>
                </v-row>
                <v-btn text color="primary" @click="addDetailInvoice" v-if="invoice.invoices?.length < 3">
                Add Invoice Item
                </v-btn>
                <v-spacer></v-spacer>
                <div>Total Price (RM): {{ calculateTotalPrice() }}</div>
                </v-form>
            </v-card-text>
            <v-btn color="primary" @click="submitEditInvoice">Edit Invoice</v-btn>
            <v-btn color="primary" @click="showForm = false">Cansel</v-btn>
        </v-card>
    </v-container>
</template>
    
<script>
import { jsPDF } from 'jspdf';
export default {
    data() {
        return {
            isEditMode: false, // 默认为添加模式
            showForm: false, // 控制表单显示的变量
            invoices: [],
            invoice: {
                invoices: [
                    { description: '', price: '' }, // Default item to show initially
                ],
                invoice_date: new Date().toISOString().substr(0, 10),
                invoice_type: '1',
                invoice_number: '',
                invoice_total: '',
                invoice_status: '',
                phone: '',
                item_description: '',
                item_price: '',
                total_price: '',
                invoiceTo: 'Cash', // 用于选择"To"的值
                customInvoiceTo: '', // 用于存储自定义"To"的值
            }
        };
    },
    created() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    },
    mounted() {
        this.fetchInvoicesData();
    },
    computed: {
        filteredInvoices() {
            return this.invoices
            .filter(invoice => Number(invoice.invoice_status) === 1)
            .sort((a, b) => {
                // 将日期字符串转换为日期对象进行比较
                const dateA = new Date(a.updated_at);
                const dateB = new Date(b.updated_at);
                return dateB - dateA; // 降序排序，最新的日期在前
            });
        },
        totalPrice() {
            return this.invoice.invoices.reduce((total, item) => {
            return total + Number(item.price);
            }, 0);
        },
        finalInvoiceTo() {
            return this.invoiceTo === 'Custom' ? this.customInvoiceTo : this.invoiceTo;
        },
        // calculateTotalPrice() {
        //     return (this.invoice.invoices || []).reduce((total, item) => total + Number(item.price), 0);
        // },  
        // calculateTotalPrice() {
        //     return (this.invoice.items || []).reduce((total, item) => total + item.price, 0);
        // }
    },
    methods: {
        reprint(invoice) {
            this.invoice = Object.assign({}, invoice);
            this.generateInvoicePDF(invoice);
        },
        submitInvoiceAndPrint(invoice) {
            this.submitNewInvoice(invoice).then(() => {
            this.generatePDF(invoice);
            }).catch(error => {
            console.error('Failed to create new invoice and print:', error);
            });
        },

        markAsCompleted(invoice) {
            const updatedStatus = { status: 2 };
            axios.put(`/api/invoices/${invoice.id}`, updatedStatus)
            .then(response => {
                this.fetchInvoicesData();
            })
            .catch(error => {
                console.error('Error updating invoice status:', error);
            });
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
        },

        formatTime(dateString) {
            const options = { hour: '2-digit', minute: '2-digit', hour12: true };
            const date = new Date(dateString);
            return date.toLocaleTimeString('en-US', options);
        },
        showFormMethod() {
            // this.resetForm();
            this.showForm = true; // 这将隐藏表单
            if (!this.invoice.invoices || !this.invoice.invoices.length) {
            // Initialize with a default item if empty
            this.invoice.invoices = [{ item_description: '', item_price: '' }];
            }
        },
        closeForm() {
            this.showForm = false; // 这将隐藏表单
            this.isEditMode = false; // 这将隐藏表单
            this.resetForm();
        },
        fetchInvoicesData() {
        axios.get('/api/invoices')
        .then(response => {
        this.invoices = response.data;
        })
        .catch(error => {
        console.error(error);
        });
        },
        addDetailInvoice() {
            if (this.invoice.invoices.length < 3) {
            this.invoice.invoices.push({ description: '', price: '' });
            }
        },
        addInvoice() {
        this.invoice.invoices.push({ detail: '', price: '' });
        this.updateTotalPrice(); // 更新总价
        this.resetForm();
        },
        updateInvoiceDetail(index, newDetail, price) {
        this.$set(this.invoice.invoices, index, { detail: newDetail, price: price });
        this.updateTotalPrice(); // 更新总价
        },
        editItem(invoice) {
            // 其他初始化代码...

            // 确保发票的 id 也被设置
            this.invoice.id = invoice.id;

            // 转换 item_description 和 item_price 到 invoices 数组
            if (invoice.item_description && invoice.item_price && Array.isArray(invoice.item_description) && Array.isArray(invoice.item_price)) {
                this.invoice.invoices = invoice.item_description.map((description, index) => ({
                    description: description,
                    price: invoice.item_price[index] || ''
                }));
            } else {
                // 如果没有提供 item_description 和 item_price，或者它们不是数组
                this.invoice.invoices = [{ description: '', price: '' }];
            }

            // 设置其他字段...
            this.invoice.invoiceTo = invoice.invoiceTo || 'Cash';
            this.invoice.customInvoiceTo = invoice.customInvoiceTo || '';
            this.invoice.totalprice = invoice.totalprice || '';
            this.invoice.invoice_status = invoice.invoice_status || '';
            this.invoice.phone = invoice.phone || '';
            this.invoice.passcode = invoice.passcode || '';
            // 确保设置了其他需要的字段

            this.isEditMode = true;
            this.showForm = true;
        },
        submitNewInvoice() {
        const postData = {
            invoice_date: new Date(new Date().getTime() + (8*60*60*1000)).toISOString().slice(0, 19).replace('T', ' '),
            item_description: this.invoice.invoices.map(item => item.description),
            item_price: this.invoice.invoices.map(item => item.price),
            invoice_type: this.invoice.invoice_type,
            invoice_status: 1,
            phone: this.invoice.phone,
            total: this.calculateTotalPrice(),
            invoiceTo: this.invoice.invoiceTo,
        };

        return axios.post('/api/invoices', postData)
        .then(response => {
            // Handle success
            const invoice = response.data;
            this.fetchInvoicesData();
            this.closeForm();
            this.generateInvoicePDF(invoice);
            console.log(response.data);
            return response; // Ensure a response is returned
        })
        .catch(error => {
            // Handle error
            console.error(error);
            throw error; // Re-throw the error to be caught by the caller
        });
        },

        submitEditInvoice() {
        this.invoice.totalprice = this.calculateTotalPrice();
        const updatedData = {
            invoice_date: this.invoice_date,
            item_description: this.invoice.invoices.map(item => item.description),
            item_price: this.invoice.invoices.map(item => item.price),
            invoice_type: this.invoice.invoice_type,
            invoice_status: 1,
            phone: this.invoice.phone,
            total: this.calculateTotalPrice()
        };
        axios.put(`/api/invoices/${this.invoice.id}`, updatedData)
            .then(response => {
            console.log(this.invoice.invoices); 
            this.fetchInvoicesData(); // 重新获取最新的维修记录列表
            this.showForm = false; // 隐藏表单
            this.isEditMode = false; // 退出编辑模式
            })
            .catch(error => {
            console.error('Error updating invoice:', error);
            });
        },
        updateTotalPrice() {
        this.invoice.totalprice = this.calculateTotalPrice();
        },
        // calculateTotalPrice() {
        // return this.invoice.invoices.reduce((total, item) => {
        //     return total + Number(item.price);
        // }, 0);
        // },
        deleteItem(invoice) {
        // 弹出确认对话框
        if (window.confirm(`Are you sure you want to delete the invoice?`)) {
            // 用户确认删除，发送请求到服务器删除项
            axios.delete(`/api/invoices/${invoice.id}`)
            .then(() => {
                // 从本地列表移除
                this.invoices = this.invoices.filter((data) => data.id !== invoice.id);
            })
            .catch(error => {
                console.error(error);
            });
        } else {
            // 用户取消删除
            console.log('Delete action cancelled by the user.');
        }
        },
        calculateTotalPrice() {
        return this.invoice.invoices.reduce((total, item) => {
            return total + Number(item.price);
        }, 0);
        },
        
        generateInvoicePDF(invoice) {
        this.showWarrantyModal = false;
        // 创建 A5 纸张大小的 PDF，横向
        const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a5'
        });
        const pageWidth = doc.internal.pageSize.getWidth();
        const lineHeight = 10;
        let currentLine = 10; // 根据需要调整
        const margin = 10;

        // 添加公司 Logo
        // 注意：你需要将 'your-logo.png' 替换为实际的图片路径或者Base64编码的图片数据
        // doc.addImage('storage/fl.png', 'PNG', margin, currentLine, 40, 20);
        // currentLine += 30; // 根据 Logo 的大小调整

        doc.setFontSize(16);
        let text = 'Future Link Solution';
        let textWidth = doc.getTextWidth(text);
        doc.text(text, (pageWidth - textWidth) / 2, currentLine);
        currentLine += lineHeight * 0.5;

        // 添加其他信息，居中
        doc.setFontSize(10);
        let ssm = '(JM0997245-M)';
        let addressLine1 = 'East Wing, Central Park No.1,';
        let addressLine2 = 'Jalan PJU 5/7, 47810 Petaling Jaya,';
        let addressLine3 = 'Selangor, Malaysia.';
        let phoneLine = 'Phone: 014-367 3794(Hao)  016-926 6945(CY)'; // 假设的电话号码

        // 减小行高值
        let smallerLineHeight = 5; // 或者你想要的任何较小的值

        let textWidthssm = doc.getTextWidth(ssm);
        let textWidth1 = doc.getTextWidth(addressLine1);
        let textWidth2 = doc.getTextWidth(addressLine2);
        let textWidth3 = doc.getTextWidth(addressLine3);
        let textWidthPhone = doc.getTextWidth(phoneLine);

        doc.text(ssm, (pageWidth - textWidthssm) / 2, currentLine);
        currentLine += smallerLineHeight;
        doc.text(addressLine1, (pageWidth - textWidth1) / 2, currentLine);
        currentLine += smallerLineHeight;
        doc.text(addressLine2, (pageWidth - textWidth2) / 2, currentLine);
        currentLine += smallerLineHeight;
        doc.text(addressLine3, (pageWidth - textWidth3) / 2, currentLine);
        currentLine += smallerLineHeight;

        // 添加电话号码，居中
        doc.text(phoneLine, (pageWidth - textWidthPhone) / 2, currentLine);
        currentLine += lineHeight * 1; // 或者更多，根据需要调整

        doc.setFontSize(12); // 设置标题的字体大小
        doc.text('Sale Invoice', (pageWidth - doc.getTextWidth('Sale Invoice')) / 2, currentLine);
        currentLine += lineHeight; // 更新currentLine以便在标题下方留出空间

        // 添加 "Bill no" 文本
        doc.setFontSize(10);
        // let billNoText = `Bill No: ${repair.id}`; // repair is now the passed object
        // doc.text(billNoText, margin, currentLine);
        const toText = `To: ${invoice.invoiceTo}`;
        const toTextWidth = doc.getTextWidth(toText);
        const toTextXPosition = pageWidth - margin - toTextWidth;
        doc.text(`Invoice No: ${invoice.invoice_number}`, margin, currentLine);
        doc.text(toText, toTextXPosition, currentLine);
        currentLine += 10; // 更新currentLine以便在 "Bill no" 下方留出空间

        // 添加生成的日期和时间
        doc.setFontSize(12);
        const dateString = `Date: ${this.formatDate(invoice.invoice_date)}`;
        const timeString = `Time: ${this.formatTime(invoice.invoice_date)}`;
        // 计算时间文本的宽度
        const timeTextWidth = doc.getTextWidth(timeString);

        // 添加日期和时间，确保时间在页面的右侧与日期对齐
        doc.text(dateString, margin, currentLine);
        doc.text(timeString, pageWidth - margin - timeTextWidth, currentLine);
        currentLine += lineHeight; // 移动到下一行
        // currentLine += lineHeight * 0.5;

        let totalContentHeight = 0;
        let initialLine = currentLine; // 保存初始行位置

        invoice.item_description.forEach((item, index) => {
            const priceText = `Price: ${invoice.item_price[index]}`;
            const priceTextWidth = doc.getTextWidth(priceText);
            const paddingRight = 2;
            const priceXPosition = pageWidth - margin - priceTextWidth - paddingRight;
            const maxDetailWidth = priceXPosition - margin - 10;
            let splitDetailText = doc.splitTextToSize(item, maxDetailWidth);
            let detailTextHeight = lineHeight * splitDetailText.length;
            totalContentHeight += detailTextHeight + 5; // 更新总内容高度

            // 绘制详情文本
            let detailTextCurrentLine = currentLine; // 用于详情文本的当前行
            splitDetailText.forEach((line, lineIndex) => {
            doc.text(line, margin + 2, detailTextCurrentLine + lineHeight * (lineIndex + 1));
            });

            // 绘制价格文本，确保它在最后一行详情文本的旁边
            let priceTextLine = detailTextCurrentLine + lineHeight * (splitDetailText.length); // 计算价格文本的行位置
            doc.text(priceText, priceXPosition, priceTextLine);

            // 更新 currentLine 为下一个项目
            currentLine += detailTextHeight + 5;
        });

        // 绘制大框来包含所有项目
        // 注意：这里使用 initialLine 作为起始位置
        doc.rect(margin, initialLine, pageWidth - margin * 2, totalContentHeight);

        // 重置 currentLine 为框下方的位置，以便继续绘制其他内容
        currentLine = initialLine + totalContentHeight;

        // 移动到大框的下面
        currentLine += lineHeight * 1.0;

        // 显示总价
        doc.setFontSize(14);
        const totalprice = invoice.total;
        doc.text(`Total Price (RM): ${totalprice}`, margin, currentLine);

        // // 在总价下面增加一些空间，然后再添加签名行
        // currentLine += lineHeight * 3; // 根据需要调整空间大小

        // // 添加签名行
        // const signatureWidth = 60; // 签名线的宽度
        // const signatureSpacing = 5; // 签名线与文本之间的空间
        // doc.setFontSize(10);

        // // 计算文本的起始位置，这样线会在文本上方
        // const signatureTextYPosition = currentLine + signatureSpacing;

        // // 客户签名在左边
        // doc.line(margin, currentLine, margin + signatureWidth, currentLine); // 签名线
        // doc.text('Customer Signature', margin, signatureTextYPosition); // 文本在线下方

        // // 授权签名在右边
        // const rightSignatureMargin = pageWidth - margin - signatureWidth; // 计算右边签名的起始位置
        // doc.line(rightSignatureMargin, currentLine, pageWidth - margin, currentLine); // 签名线
        // doc.text('Authorized Signature', rightSignatureMargin, signatureTextYPosition); // 文本在线下方

        // // 更新 currentLine 以便在签名区域下方留出空间
        // currentLine += signatureSpacing + lineHeight;

        // 添加页脚
        doc.setFontSize(7);
        let footerText = 'Thank you for your business support!';
        let footerTextWidth = doc.getTextWidth(footerText);
        doc.text(footerText, (pageWidth - footerTextWidth) / 2, doc.internal.pageSize.height - 10);

        // 生成 PDF 并打开打印对话框
        const pdfOutput = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfOutput);
        const printWindow = window.open(pdfUrl);
        printWindow.onload = function() {
        printWindow.focus();
        printWindow.print();
        URL.revokeObjectURL(pdfUrl);
        };
        },
        resetForm() {
            this.invoice = {
            passcode: '',
            phone: '',
            invoices: [{ detail: '', price: '' }],
            };
        },
    }
};
</script>
<style scoped>
.invoice-item {
    word-wrap: break-word; /* 在长单词或URL内部进行换行 */
}
</style>