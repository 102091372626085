<template>
  <v-container fluid>
    <v-row>
      <v-col><h1>Completed</h1></v-col>
      <!-- <v-col style="text-align: right;"><v-btn v-if="!showForm" color="primary" @click="showFormMethod">Add Repair</v-btn></v-col> -->
    </v-row>
    <v-simple-table v-if="!showForm">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Date</th>
            <th class="text-left">Passcode</th>
            <th class="text-left">Phone</th>
            <th class="text-left">Repairs</th>
            <th class="text-left">Total Price</th>
            <th class="text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="repair in sortedRepairs" :key="repair.id">
            <td>
              <div>{{ formatDate(repair.created_at) }}</div>
              <div>{{ formatTime(repair.created_at) }}</div>
            </td>
            <td>{{ repair.passcode }}</td>
            <td>{{ repair.phone }}</td>
            <td>
              <ul>
                <li v-for="(item, index) in repair.repairs" :key="index" style="width: 310px;" class="repair-item">
                  {{ item.detail }} - {{ item.price }}
                </li>
              </ul>
            </td>
            <td>{{ repair.totalprice }}</td>
            <td>
              <!-- <v-btn small color="default" @click="reprint(repair)">Print</v-btn>
              <v-btn small color="primary" @click="editItem(repair)">Edit</v-btn>
              <v-btn small color="error" @click="deleteItem(repair)">Delete</v-btn>
              <v-btn small color="success" @click="markAsCompleted(repair)">Completed</v-btn> -->
              <v-btn color="success" @click="openWarrantyModal(repair)">Generate Invoice</v-btn>
              <!-- <v-btn color="success" @click="prepareAndSaveInvoice(repair)">Generate Invoice</v-btn> -->
              <v-btn small color="red" @click="markAsUnCompleted(repair)">UnCompleted</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card v-if="showForm && !isEditMode">
      <v-card-title class="headline">Add Repair</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
               <v-text-field v-model="repair.passcode" label="Passcode" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="repair.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in repair.repairs" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Repair Detail" outlined dense></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addDetailRepair" v-if="repair.repairs.length < 3">
            Add Repair
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ calculateTotalPrice() }}</div>
        </v-form>
      </v-card-text>
      <v-btn color="primary" @click="showForm = false">Cansel</v-btn>
      <v-btn color="success" @click="submitNewRepair">New Repair</v-btn>
      <!-- <v-btn color="success" @click="submitRepairAndPrint">New Repair & Print</v-btn> -->
    </v-card>

    <v-card v-if="showForm && isEditMode">
      <v-card-title class="headline">Edit Repair</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
               <v-text-field v-model="repair.passcode" label="Passcode" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="repair.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in repair.repairs" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Repair Detail" outlined dense @input="updateRepairDetail(index, $event, item.price)"></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense @input="updateRepairDetail(index, item.detail, $event.target.value)"></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addDetailRepair" v-if="repair.repairs.length < 3">
            Add Repair
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ totalPrice }}</div>
        </v-form>
      </v-card-text>
      <v-btn color="primary" @click="submitEditRepair">Submit Edit Repair</v-btn>
      <v-btn color="primary" @click="showForm = false">Cansel</v-btn>
    </v-card>
    <v-dialog v-model="showWarrantyModal" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Enter Warranty Information
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-for="(item, index) in currentRepair.repairs" :key="index">
              <v-col cols="12">
                <v-text-field
                  type="number"
                  v-model="item.warranty"
                  :label="`Warranty for ${item.detail}`"
                  placeholder="Warranty days"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="green darken-1" text @click="generateInvoice(currentRepair)">Generate Invoice</v-btn> -->
          <v-btn color="green darken-1" text @click="addWarrantyInfo">Generate Invoice</v-btn>
          <v-btn color="red darken-1" text @click="showWarrantyModal = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-card v-if="showForm">
      <v-card-title class="headline">Repair</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
               <v-text-field v-model="repair.passcode" label="Passcode" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="repair.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in repair.repairs" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Repair Detail" outlined dense @input="updateRepairDetail(index, item.detail, item.price)"></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense @input="updateRepairDetail(index, item.detail, item.price)"></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addRepair" v-if="repair.repairs.length < 3">
            Add Repair
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ calculateTotalPrice() }}</div>
          <v-btn color="success" @click="generatePDF" class="white--text" v-if="!isEditMode">
            Generate PDF
          </v-btn>
          <v-btn color="primary" @click="updateRepair" v-if="isEditMode">
            Update Repair
          </v-btn>
          <v-btn color="primary" @click="showForm = false">
            Cansel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card> -->
  </v-container>
</template>
  
<script>
import { jsPDF } from 'jspdf';
export default {
  data() {
    return {
      showWarrantyModal: false,
      isEditMode: false, // 默认为添加模式
      showForm: false, // 控制表单显示的变量
      currentRepair: null, // Initialize currentRepair as null or with an appropriate structure
      currentRepair: {
        repairs: [] // Initialize the repairs array
      },
      repairs: [],
      repair: {
        passcode: '',
        phone: '',
        repairs: [
          { detail: '', price: '' } // 初始化时包含一个空的修理项目
      ],
        price: '',
      }
    };
  },
  created() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  },
  mounted() {
    this.fetchRepairsData();
  },
  computed: {
    sortedRepairs() {
      // 首先过滤出状态为 true 的维修记录
      const filteredRepairs = this.repairs.filter(repair => Number(repair.status) === 2 );

      // 然后对这些记录进行排序
      return filteredRepairs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
    totalPrice() {
      return this.repair.repairs.reduce((total, item) => {
        return total + Number(item.price);
      }, 0);
    },
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
    },

    formatTime(dateString) {
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      const date = new Date(dateString);
      return date.toLocaleTimeString('en-US', options);
    },
    showFormMethod() {
      this.resetForm();
      this.showForm = true; // 这将隐藏表单
    },
    closeForm() {
      this.showForm = false; // 这将隐藏表单
      this.isEditMode = false; // 这将隐藏表单
      this.resetForm();
    },
    fetchRepairsData() {
    axios.get('/api/repair')
      .then(response => {
        this.repairs = response.data;
      })
      .catch(error => {
        console.error(error);
      });
    },
    submitNewRepair() {
      const postData = {
        passcode: this.repair.passcode,
        phone: this.repair.phone,
        repairs: this.repair.repairs,
        totalprice: this.calculateTotalPrice()
      };

      // 发送POST请求到API
      return axios.post('/api/repair', postData)
      .then(response => {
        // Handle success
        this.fetchRepairsData();
        this.closeForm();
        console.log(response.data);
        return response; // Ensure a response is returned
      })
      .catch(error => {
        // Handle error
        console.error(error);
        throw error; // Re-throw the error to be caught by the caller
      });
    },
    updateTotalPrice() {
      this.repair.totalprice = this.calculateTotalPrice();
    },
    calculateTotalPrice() {
      return this.repair.repairs.reduce((total, item) => {
        return total + Number(item.price);
      }, 0);
    },
    calculateTotalPrice() {
      return this.repair.repairs.reduce((total, item) => {
        return total + Number(item.price);
      }, 0);
    },
    prepareAndSaveInvoice(repair) {
      // 复制 repair 数据到 invoice
      const invoiceData = {
        invoice_date: new Date(new Date().getTime() + (8*60*60*1000)).toISOString().slice(0, 19).replace('T', ' '),
        invoice_type: '1',
        invoice_status: '1',
        phone: repair.phone,
        item_description: repair.repairs.map(r => {
          // 检查是否有 warranty 信息并且 warranty 信息不为空
          if (r.warranty && r.warranty.trim() !== '') {
            // 如果有 warranty，将其添加到描述后面
            return `${r.detail} - Warranty: ${r.warranty} days`;
          } else {
            // 如果没有 warranty 或者 warranty 为空，只返回原始描述
            return r.detail;
          }
        }),
        item_price: repair.repairs.map(r => r.price),
        total: repair.repairs.reduce((total, r) => total + Number(r.price), 0)
      };

      // 发送数据到服务器并创建发票
      axios.post('/api/invoices', invoiceData)
        .then(response => {
          // 服务器应该返回新创建的发票ID
          const invoice = response.data; // 假设服务器返回的是完整的发票对象
          console.log(invoice.id); // 发票ID
          console.log(invoice.invoice_number); // 服务器生成的发票号码
          // 使用返回的发票数据生成PDF
          this.generateInvoicePDF(invoice.id, invoice);
          this.markAsInvoiced(repair);
          this.fetchRepairsData();
        })
        .catch(error => {
          console.error('Error posting invoice data:', error);
        });
    },
    markAsInvoiced(repair) {
      // Set the status to 3 to indicate a specific completion state, such as invoice generated
      const updatedStatus = { status: 3 };

      // Send the updated status to the server
      axios.put(`/api/repair/${repair.id}`, updatedStatus)
        .then(response => {
          this.fetchRepairsData(); // Refresh the data to reflect the updated status
          // Optionally, you can remove the repair from the list if the update is successful
          // this.repairs = this.repairs.filter(item => item.id !== repair.id);
        })
        .catch(error => {
          // Handle any errors
          console.error('Error updating repair status:', error);
        });
    },
    generateInvoicePDF(invoiceId, invoiceData) {
      this.showWarrantyModal = false;
      // 创建 A5 纸张大小的 PDF，横向
      const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a5'
      });
      const pageWidth = doc.internal.pageSize.getWidth();
      const lineHeight = 10;
      let currentLine = 10; // 根据需要调整
      const margin = 10;

      // 添加公司 Logo
      // 注意：你需要将 'your-logo.png' 替换为实际的图片路径或者Base64编码的图片数据
      // doc.addImage('storage/fl.png', 'PNG', margin, currentLine, 40, 20);
      // currentLine += 30; // 根据 Logo 的大小调整

      doc.setFontSize(16);
      let text = 'Future Link Solution';
      let textWidth = doc.getTextWidth(text);
      doc.text(text, (pageWidth - textWidth) / 2, currentLine);
      currentLine += lineHeight * 0.5;

      // 添加其他信息，居中
      doc.setFontSize(10);
      let ssm = '(JM0997245-M)';
      let addressLine1 = 'East Wing, Central Park No.1,';
      let addressLine2 = 'Jalan PJU 5/7, 47810 Petaling Jaya,';
      let addressLine3 = 'Selangor, Malaysia.';
      let phoneLine = 'Phone: 014-367 3794(Hao)  016-926 6945(CY)'; // 假设的电话号码

      // 减小行高值
      let smallerLineHeight = 5; // 或者你想要的任何较小的值

      let textWidthssm = doc.getTextWidth(ssm);
      let textWidth1 = doc.getTextWidth(addressLine1);
      let textWidth2 = doc.getTextWidth(addressLine2);
      let textWidth3 = doc.getTextWidth(addressLine3);
      let textWidthPhone = doc.getTextWidth(phoneLine);

      doc.text(ssm, (pageWidth - textWidthssm) / 2, currentLine);
      currentLine += smallerLineHeight;
      doc.text(addressLine1, (pageWidth - textWidth1) / 2, currentLine);
      currentLine += smallerLineHeight;
      doc.text(addressLine2, (pageWidth - textWidth2) / 2, currentLine);
      currentLine += smallerLineHeight;
      doc.text(addressLine3, (pageWidth - textWidth3) / 2, currentLine);
      currentLine += smallerLineHeight;

      // 添加电话号码，居中
      doc.text(phoneLine, (pageWidth - textWidthPhone) / 2, currentLine);
      currentLine += lineHeight * 1; // 或者更多，根据需要调整

      doc.setFontSize(12); // 设置标题的字体大小
      doc.text('Sale Invoice', (pageWidth - doc.getTextWidth('Sale Invoice')) / 2, currentLine);
      currentLine += lineHeight; // 更新currentLine以便在标题下方留出空间

      // 添加 "Bill no" 文本
      doc.setFontSize(10);
      // let billNoText = `Bill No: ${repair.id}`; // repair is now the passed object
      // doc.text(billNoText, margin, currentLine);
      doc.text(`Invoice No: ${invoiceData.invoice_number}`, margin, currentLine);
      currentLine += 10; // 更新currentLine以便在 "Bill no" 下方留出空间

      // 添加生成的日期和时间
      doc.setFontSize(12);
      const dateString = `Date: ${this.formatDate(invoiceData.invoice_date)}`;
      const timeString = `Time: ${this.formatTime(invoiceData.invoice_date)}`;
      // 计算时间文本的宽度
      const timeTextWidth = doc.getTextWidth(timeString);

      // 添加日期和时间，确保时间在页面的右侧与日期对齐
      doc.text(dateString, margin, currentLine);
      doc.text(timeString, pageWidth - margin - timeTextWidth, currentLine);
      currentLine += lineHeight; // 移动到下一行
      // currentLine += lineHeight * 0.5;

      let totalContentHeight = 0;
      let initialLine = currentLine; // 保存初始行位置

      invoiceData.item_description.forEach((item, index) => {
        const priceText = `Price: ${invoiceData.item_price[index]}`;
        const priceTextWidth = doc.getTextWidth(priceText);
        const paddingRight = 2;
        const priceXPosition = pageWidth - margin - priceTextWidth - paddingRight;
        const maxDetailWidth = priceXPosition - margin - 10;
        let splitDetailText = doc.splitTextToSize(item, maxDetailWidth);
        let detailTextHeight = lineHeight * splitDetailText.length;
        totalContentHeight += detailTextHeight + 5; // 更新总内容高度

        // 绘制详情文本
        let detailTextCurrentLine = currentLine; // 用于详情文本的当前行
        splitDetailText.forEach((line, lineIndex) => {
          doc.text(line, margin + 2, detailTextCurrentLine + lineHeight * (lineIndex + 1));
        });

        // 绘制价格文本，确保它在最后一行详情文本的旁边
        let priceTextLine = detailTextCurrentLine + lineHeight * (splitDetailText.length); // 计算价格文本的行位置
        doc.text(priceText, priceXPosition, priceTextLine);

        // 更新 currentLine 为下一个项目
        currentLine += detailTextHeight + 5;
      });

      // 绘制大框来包含所有项目
      // 注意：这里使用 initialLine 作为起始位置
      doc.rect(margin, initialLine, pageWidth - margin * 2, totalContentHeight);

      // 重置 currentLine 为框下方的位置，以便继续绘制其他内容
      currentLine = initialLine + totalContentHeight;

      // 移动到大框的下面
      currentLine += lineHeight * 1.0;

      // 显示总价
      const totalprice = invoiceData.total;
      doc.text(`Total Price (RM): ${totalprice}`, margin, currentLine);

      // // 在总价下面增加一些空间，然后再添加签名行
      // currentLine += lineHeight * 3; // 根据需要调整空间大小

      // // 添加签名行
      // const signatureWidth = 60; // 签名线的宽度
      // const signatureSpacing = 5; // 签名线与文本之间的空间
      // doc.setFontSize(10);

      // // 计算文本的起始位置，这样线会在文本上方
      // const signatureTextYPosition = currentLine + signatureSpacing;

      // // 客户签名在左边
      // doc.line(margin, currentLine, margin + signatureWidth, currentLine); // 签名线
      // doc.text('Customer Signature', margin, signatureTextYPosition); // 文本在线下方

      // // 授权签名在右边
      // const rightSignatureMargin = pageWidth - margin - signatureWidth; // 计算右边签名的起始位置
      // doc.line(rightSignatureMargin, currentLine, pageWidth - margin, currentLine); // 签名线
      // doc.text('Authorized Signature', rightSignatureMargin, signatureTextYPosition); // 文本在线下方

      // // 更新 currentLine 以便在签名区域下方留出空间
      // currentLine += signatureSpacing + lineHeight;

      // 添加页脚
      doc.setFontSize(7);
      let footerText = 'Thank you for your business support!';
      let footerTextWidth = doc.getTextWidth(footerText);
      doc.text(footerText, (pageWidth - footerTextWidth) / 2, doc.internal.pageSize.height - 10);

      // 生成 PDF 并打开打印对话框
      const pdfOutput = doc.output('blob');
      const pdfUrl = URL.createObjectURL(pdfOutput);
      const printWindow = window.open(pdfUrl);
      printWindow.onload = function() {
      printWindow.focus();
      printWindow.print();
      URL.revokeObjectURL(pdfUrl);
      };
    },
    resetForm() {
      // 重置 repair 对象
      this.repair = {
        passcode: '',
        phone: '',
        repairs: [{ detail: '', price: '' }],
      };
    },
    openWarrantyModal(repair) {
      this.currentRepair = repair; // 假设您有一个用于存储当前修理对象的数据属性
      this.showWarrantyModal = true;
    },
    addWarrantyInfo() {
      this.showWarrantyModal = false; // 关闭模态窗口
      this.prepareAndSaveInvoice(this.currentRepair); // 使用当前修理项目（已更新保修信息）生成发票
    },
    markAsUnCompleted(repair) {
      // Set the status to false to indicate completion
      const updatedStatus = { status: 1 };

      // Send the updated status to the server
      axios.put(`/api/repair/${repair.id}`, updatedStatus)
        .then(response => {
          this.fetchRepairsData();
          // Remove the repair from the list if the update is successful
          // this.repairs = this.repairs.filter(item => item.id !== repair.id);
        })
        .catch(error => {
          // Handle any errors
          console.error('Error updating repair status:', error);
        });
    },
  }
};
</script>
<style scoped>
.repair-item {

  word-wrap: break-word; /* 在长单词或URL内部进行换行 */

}
</style>