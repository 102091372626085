<template>
  <v-container fluid>
    <v-row>
      <v-col><h1>Quotation</h1></v-col>
      <v-col style="text-align: right;"><v-btn v-if="!showForm" color="primary" @click="showFormMethod">Add Quotation</v-btn></v-col>
    </v-row>
    <v-simple-table v-if="!showForm">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Date</th>
            <th class="text-left">Phone</th>
            <th class="text-left">Repair</th>
            <th class="text-left">Total Price</th>
            <th class="text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="quotation in sortedQuotation" :key="quotation.id">
            <td>
              <div>{{ formatDate(quotation.updated_at) }}</div>
              <div>{{ formatTime(quotation.updated_at) }}</div>
            </td>
            <td>{{ quotation.phone }}</td>
            <td>
              <ul>
                <li v-for="(item, index) in quotation.quotations" :key="index" style="width: 300px;" class="quotation-item">
                  {{ item.detail }} - {{ item.price }}
                </li>
              </ul>
            </td>
            <td>{{ quotation.totalprice }}</td>
            <td>
              <v-btn small color="default" @click="reprint(quotation)">Print</v-btn>
              <v-btn small color="primary" @click="editItem(quotation)">Edit</v-btn>
              <v-btn small color="error" @click="deleteItem(quotation)">Delete</v-btn>
              <v-btn small color="success" @click="markAsCompleted(quotation)">Repair</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card v-if="showForm && !isEditMode">
      <v-card-title class="headline">Add Quotation</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="quotation.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in quotation.quotations" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Quotation Detail" outlined dense></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addDetailQuotation" v-if="quotation.quotations.length < 3">
            Add Quotation
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ calculateTotalPrice() }}</div>
        </v-form>
      </v-card-text>
      <v-btn color="primary" @click="closeForm">Cansel</v-btn>
      <v-btn color="success" @click="submitNewQuotation">New Quotation</v-btn>
      <v-btn color="success" @click="submitNewQuotationAndPrint">New Quotation & Print</v-btn>
    </v-card>

    <v-card v-if="showForm && isEditMode">
      <v-card-title class="headline">Edit Quotation</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="quotation.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in quotation.quotations" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Quotation Detail" outlined dense @input="updateQuotationDetail(index, $event, item.price)"></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense @input="updateQuotationDetail(index, item.detail, $event.target.value)"></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addDetailQuotation" v-if="quotation.quotations.length < 3">
            Add Quotation
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ totalPrice }}</div>
        </v-form>
      </v-card-text>
      <v-btn color="primary" @click="submitEditQuotation">Edit Quotation</v-btn>
      <v-btn color="primary" @click="closeForm">Cansel</v-btn>
    </v-card>

    <!-- <v-card v-if="showForm">
      <v-card-title class="headline">Quotation</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
               <v-text-field v-model="quotation.passcode" label="Passcode" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="quotation.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in quotation.quotations" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Quotation Detail" outlined dense @input="updateQuotationDetail(index, item.detail, item.price)"></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense @input="updateQuotationDetail(index, item.detail, item.price)"></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addQuotation" v-if="quotation.quotations.length < 3">
            Add Quotation
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ calculateTotalPrice() }}</div>
          <v-btn color="success" @click="generatePDF" class="white--text" v-if="!isEditMode">
            Generate PDF
          </v-btn>
          <v-btn color="primary" @click="updateQuotation" v-if="isEditMode">
            Update Quotation
          </v-btn>
          <v-btn color="primary" @click="showForm = false">
            Cansel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card> -->
  </v-container>
</template>
  
<script>
import { jsPDF } from 'jspdf';
import { countBy } from 'lodash';
export default {
  data() {
    return {
      isEditMode: false, // 默认为添加模式
      showForm: false, // 控制表单显示的变量
      quotations: [],
      quotation: {
        phone: '',
        quotations: [
          { detail: '', price: '' } // 初始化时包含一个空的修理项目
      ],
        price: '',
        totalprice: '',
      }
    };
  },
  created() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  },
  mounted() {
    this.fetchQuotationsData();
  },
  computed: {
    
    sortedQuotation() {
      const filteredQuotations = this.quotations.filter(quotation => Number(quotation.status) === 1);
      console.log(filteredQuotations); // 查看过滤后的结果

      const sorted = filteredQuotations.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      console.log(sorted); // 查看排序后的结果
      return sorted;
    },
    totalPrice() {
      return this.quotation.quotations.reduce((total, item) => {
        return total + Number(item.price);
      }, 0);
    },
  },
  methods: {
    reprint(quotation) {
      this.quotation = Object.assign({}, quotation);
      // 假设 generatePDF 方法需要一个 quotation 对象作为参数
      this.generatePDF(quotation);
      this.resetForm();
    },
    // submitQuotationAndPrint(quotation) {
    //   this.submitNewQuotation(quotation).then((response) => {
    //     // Directly use the response data for generating PDF
    //     // This ensures that the data used for PDF generation includes the new quotation ID
    //     const updatedQuotation = response.data;
    //     this.generatePDF(updatedQuotation);
    //   }).catch(error => {
    //     console.error('Failed to create new quotation and print:', error);
    //   });
    // },

    markAsCompleted(quotation) {
      // Set the status to 2 to indicate completion
      const updatedStatus = { status: 2 };

      // Send the updated status to the server
      axios.put(`/api/quotations/${quotation.id}`, updatedStatus)
        .then(response => {
          // Ask for the passcode
          const passcode = prompt("Please enter the passcode, if available:");

          // Prepare the data for the /api/repair endpoint
          const repairData = {
            phone: quotation.phone,
            repairs: quotation.quotations.map(q => ({ detail: q.detail, price: q.price })),
            totalprice: quotation.totalprice,
            ...(passcode && { passcode }) // Include passcode only if it is provided
          };

          // Post the repair data to the server
          return axios.post('/api/repair', repairData);
        })
        .then(response => {
          // Handle the response from the POST request to api/repair
          console.log('Repair data posted to api/repair:', response.data);
          this.fetchQuotationsData();
          this.resetForm();
        })
        .catch(error => {
          // Handle any errors from either the PUT or the POST request
          console.error('Error during the update or post process:', error);
        });
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
    },

    formatTime(dateString) {
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      const date = new Date(dateString);
      return date.toLocaleTimeString('en-US', options);
    },
    showFormMethod() {
      this.resetForm();
      this.showForm = true; // 这将隐藏表单
    },
    closeForm() {
      this.showForm = false; // 这将隐藏表单
      this.isEditMode = false; // 这将隐藏表单
      this.resetForm();
    },
    fetchQuotationsData() {
    axios.get('/api/quotations')
      .then(response => {
        this.quotations = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  addDetailQuotation() {
    // 添加一个新的维修详情对象到数组中
    this.quotation.quotations.push({ detail: '', price: '' });
  },
  addQuotation() {
    this.quotation.quotations.push({ detail: '', price: '' });
    this.updateTotalPrice(); // 更新总价
    this.resetForm();
  },
  updateQuotationDetail(index, newDetail, price) {
    this.$set(this.quotation.quotations, index, { detail: newDetail, price: price });
    this.updateTotalPrice(); // 更新总价
  },
  editItem(quotation) {
    // 设置表单数据为选中的维修记录
    this.resetForm();
    this.quotation = Object.assign({}, quotation);
    this.isEditMode = true; // 进入编辑模式
    this.showForm = true; // 显示表单
  },
  // updateQuotationDetail(index, newDetail, price) {
  //   console.log(newDetail); // Log the new detail to see what's being passed
  //   this.$set(this.quotation.quotations, index, { detail: newDetail, price: price });
  // },
  submitNewQuotation() {
    const postData = {
      phone: this.quotation.phone,
      quotations: this.quotation.quotations,
      totalprice: this.calculateTotalPrice()
    };

    // 发送POST请求到API
    return axios.post('/api/quotations', postData)
      .then(response => {
        // Assuming the API returns the created quotation, including its ID
        // Update this.quotation with the response data
        const quotation = response.data;

        // Handle success
        this.fetchQuotationsData();
        this.closeForm();
        // this.generatePDF(quotation);
        console.log(response.data);
        return response; // Ensure a response is returned so you can use it later
      })
      .catch(error => {
        // Handle error
        console.error(error);
        throw error; // Re-throw the error to be caught by the caller
      });
    },
    submitNewQuotationAndPrint() {
      const postData = {
        phone: this.quotation.phone,
        quotations: this.quotation.quotations,
        totalprice: this.calculateTotalPrice()
      };

      // 发送POST请求到API
      return axios.post('/api/quotations', postData)
        .then(response => {
          // Assuming the API returns the created quotation, including its ID
          // Update this.quotation with the response data
          const quotation = response.data;
          // const quotation = response.data;

          // Handle success
         
          this.closeForm();
          this.generatePDF(quotation);
          console.log(response.data);
          this.fetchQuotationsData();
          return response; // Ensure a response is returned so you can use it later
        })
        .catch(error => {
          // Handle error
          console.error(error);
          throw error; // Re-throw the error to be caught by the caller
        });
      },

    // 编辑维修记录的逻辑
    submitEditQuotation() {
      // 构建要发送的数据
      this.quotation.totalprice = this.calculateTotalPrice();

      // Now, construct the updatedData object with the updated totalPrice
      const updatedData = {
        phone: this.quotation.phone,
        quotations: this.quotation.quotations,
        totalprice: this.quotation.totalprice // Ensure this is included and updated
      };

      // 发送 PUT 请求到服务器以更新维修记录
      axios.put(`/api/quotations/${this.quotation.id}`, updatedData)
        .then(response => {
          // 更新成功后的逻辑
          console.log(this.quotation.quotations); 
          this.fetchQuotationsData(); // 重新获取最新的维修记录列表
          this.showForm = false; // 隐藏表单
          this.isEditMode = false; // 退出编辑模式
        })
        .catch(error => {
          // 错误处理
          console.error('Error updating quotation:', error);
        });
    },
    updateTotalPrice() {
      this.quotation.totalPrice = this.calculateTotalPrice();
    },
    calculateTotalPrice() {
      return this.quotation.quotations.reduce((total, item) => {
        return total + Number(item.price);
      }, 0);
    },
    // updateQuotation() {
    //   // 构建要发送的数据
    //   const updatedData = {
    //     passcode: this.quotation.passcode,
    //     phone: this.quotation.phone,
    //     quotations: this.quotation.quotations.map(quotation => ({
    //       detail: quotation.detail,
    //       price: quotation.price
    //     })),
    //     totalPrice: this.calculateTotalPrice()
    //   };

    //   // 发送 PUT 请求到服务器以更新维修记录
    //   axios.put(`/api/quotations/${this.quotation.id}`, updatedData)
    //     .then(response => {
    //       // 更新成功后的逻辑
    //       this.fetchQuotationsData(); // 重新获取最新的维修记录列表
    //       this.showForm = false; // 隐藏表单
    //       this.isEditMode = false; // 退出编辑模式
    //     })
    //     .catch(error => {
    //       // 错误处理
    //       console.error('Error updating quotation:', error);
    //     });
    // },
      deleteItem(quotation) {
        // 弹出确认对话框
        if (window.confirm(`Are you sure you want to delete the quotation?`)) {
          // 用户确认删除，发送请求到服务器删除项
          axios.delete(`/api/quotations/${quotation.id}`)
            .then(() => {
              // 从本地列表移除
              this.quotations = this.quotations.filter((data) => data.id !== quotation.id);
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          // 用户取消删除
          console.log('Delete action cancelled by the user.');
        }
      },
      calculateTotalPrice() {
        return this.quotation.quotations.reduce((total, item) => {
          return total + Number(item.price);
        }, 0);
      },
      generatePDF(quotation) {
          // 创建 A5 纸张大小的 PDF，横向
          const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a5'
          });
          const pageWidth = doc.internal.pageSize.getWidth();
          const lineHeight = 10;
          let currentLine = 10; // 根据需要调整
          const margin = 10;

          // 添加公司 Logo
          // 注意：你需要将 'your-logo.png' 替换为实际的图片路径或者Base64编码的图片数据
          // doc.addImage('storage/fl.png', 'PNG', margin, currentLine, 40, 20);
          // currentLine += 30; // 根据 Logo 的大小调整

          doc.setFontSize(16);
          let text = 'Future Link Solution';
          let textWidth = doc.getTextWidth(text);
          doc.text(text, (pageWidth - textWidth) / 2, currentLine);
          currentLine += lineHeight * 0.5;

          // 添加其他信息，居中
          doc.setFontSize(10);
          let ssm = '(JM0997245-M)';
          // let text2 = 'GST No: 002-000-004-096';
          let addressLine1 = 'East Wing, Central Park No.1,';
          let addressLine2 = 'Jalan PJU 5/7, 47810 Petaling Jaya,';
          let addressLine3 = 'Selangor, Malaysia.';
          let phoneLine = 'Phone: 014-367 3794(Hao)  016-926 6945(CY)'; // 假设的电话号码

          // 减小行高值
          let smallerLineHeight = 5; // 或者你想要的任何较小的值

          let textWidthssm = doc.getTextWidth(ssm);
          let textWidth1 = doc.getTextWidth(addressLine1);
          let textWidth2 = doc.getTextWidth(addressLine2);
          let textWidth3 = doc.getTextWidth(addressLine3);
          let textWidthPhone = doc.getTextWidth(phoneLine);

          doc.text(ssm, (pageWidth - textWidthssm) / 2, currentLine);
          currentLine += smallerLineHeight;
          doc.text(addressLine1, (pageWidth - textWidth1) / 2, currentLine);
          currentLine += smallerLineHeight;
          doc.text(addressLine2, (pageWidth - textWidth2) / 2, currentLine);
          currentLine += smallerLineHeight;
          doc.text(addressLine3, (pageWidth - textWidth3) / 2, currentLine);
          currentLine += smallerLineHeight;
          

          // 添加电话号码，居中
          doc.text(phoneLine, (pageWidth - textWidthPhone) / 2, currentLine);
          currentLine += lineHeight * 1; // 或者更多，根据需要调整

          doc.setFontSize(12); // 设置标题的字体大小
          doc.text('Quotation', (pageWidth - doc.getTextWidth('Quotation')) / 2, currentLine);
          currentLine += lineHeight; // 更新currentLine以便在标题下方留出空间

          // 添加生成的日期和时间
          doc.setFontSize(12);
          const dateString = `Date: ${this.formatDate(quotation.updated_at)}`;
          const timeString = `Time: ${this.formatTime(quotation.updated_at)}`;
          // 计算时间文本的宽度
          const timeTextWidth = doc.getTextWidth(timeString);

          // 添加日期和时间，确保时间在页面的右侧与日期对齐
          doc.text(dateString, margin, currentLine);
          doc.text(timeString, pageWidth - margin - timeTextWidth, currentLine);
          currentLine += lineHeight; // 移动到下一行

          // 添加模型信息在日期下面
          // 计算电话号码文本的宽度
          const phoneString = `Phone: ${quotation.phone}`;
          const phoneTextWidth = doc.getTextWidth(phoneString);

          // 确保电话号码不会与修理项目框重叠
          // 你可能需要调整这个值以确保电话号码有足够的空间
          const phoneXPosition = pageWidth - margin - phoneTextWidth; // 减去额外的空间作为右边距

          // 添加电话号码，确保它在页面的右侧与模型信息对齐
          doc.text(phoneString, phoneXPosition, currentLine);

          currentLine += lineHeight * 0.5;
          let totalContentHeight = 0;
          let initialLine = currentLine; // 保存初始行位置
          quotation.quotations.forEach((item, index) => {
              const priceText = `Price: ${item.price}`;
              const priceTextWidth = doc.getTextWidth(priceText);
              const paddingRight = 2;
              const priceXPosition = pageWidth - margin - priceTextWidth - paddingRight;
              const maxDetailWidth = priceXPosition - margin - 10;
              let splitDetailText = doc.splitTextToSize(item.detail, maxDetailWidth);
              let detailTextHeight = lineHeight * splitDetailText.length;
              totalContentHeight += detailTextHeight + 5; // 更新总内容高度

              // 绘制详情文本
              let detailTextCurrentLine = currentLine; // 用于详情文本的当前行
              splitDetailText.forEach((line, lineIndex) => {
              doc.text(line, margin + 2, detailTextCurrentLine + lineHeight * (lineIndex + 1));
              });

              // 绘制价格文本，确保它在最后一行详情文本的旁边
              let priceTextLine = detailTextCurrentLine + lineHeight * (splitDetailText.length); // 计算价格文本的行位置
              doc.text(priceText, priceXPosition, priceTextLine);

              // 更新 currentLine 为下一个项目
              currentLine += detailTextHeight + 5;
          });

          doc.rect(margin, initialLine, pageWidth - margin * 2, totalContentHeight);

          // 重置 currentLine 为框下方的位置，以便继续绘制其他内容
          currentLine = initialLine + totalContentHeight;

          // 移动到大框的下面
          currentLine += lineHeight * 1.0;

          // 显示总价
          doc.setFontSize(14);
          const totalprice = quotation.totalprice;
          doc.text(`Total Price (RM): ${totalprice}`, margin, currentLine);

          doc.setFontSize(7);
          let footerText = 'Thank you for your business support!';
          let footerTextWidth = doc.getTextWidth(footerText);
          doc.text(footerText, (pageWidth - footerTextWidth) / 2, doc.internal.pageSize.height - 10);

          // 生成 PDF 并打开打印对话框
          const pdfOutput = doc.output('blob');
          const pdfUrl = URL.createObjectURL(pdfOutput);
          const printWindow = window.open(pdfUrl);
          printWindow.onload = function() {
          printWindow.focus();
          printWindow.print();
          URL.revokeObjectURL(pdfUrl);
          };
      },
      resetForm() {
        // 重置 quotation 对象
        this.quotation = {
          phone: '',
          quotations: [{ detail: '', price: '' }],
        };
      },
    }
  };
  </script>
  <style scoped>
  .quotation-item {

    word-wrap: break-word; /* 在长单词或URL内部进行换行 */

  }
  </style>