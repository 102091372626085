<template>
  <v-container fluid>
    <v-row>
      <v-col><h1>Repair</h1></v-col>
      <v-col style="text-align: right;"><v-btn v-if="!showForm" color="primary" @click="showFormMethod">Add Repair</v-btn></v-col>
    </v-row>
    <v-simple-table v-if="!showForm">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Date</th>
            <th class="text-left">Passcode</th>
            <th class="text-left">Phone</th>
            <th class="text-left">Repairs</th>
            <th class="text-left">Total Price</th>
            <th class="text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="repair in sortedRepairs" :key="repair.id">
            <td>
              <div>{{ formatDate(repair.updated_at) }}</div>
              <div>{{ formatTime(repair.updated_at) }}</div>
            </td>
            <td>{{ repair.passcode }}</td>
            <td>{{ repair.phone }}</td>
            <td>
              <ul>
                <li v-for="(item, index) in repair.repairs" :key="index" style="width: 300px;" class="repair-item">
                  {{ item.detail }} - {{ item.price }}
                </li>
              </ul>
            </td>
            <td>{{ repair.totalprice }}</td>
            <td>
              <v-btn small color="default" @click="reprint(repair)">Print</v-btn>
              <v-btn small color="primary" @click="editItem(repair)">Edit</v-btn>
              <v-btn small color="error" @click="deleteItem(repair)">Delete</v-btn>
              <v-btn small color="success" @click="markAsCompleted(repair)">Completed</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card v-if="showForm && !isEditMode">
      <v-card-title class="headline">Add Repair</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
               <v-text-field v-model="repair.passcode" label="Passcode" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="repair.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in repair.repairs" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Repair Detail" outlined dense></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addDetailRepair" v-if="repair.repairs.length < 3">
            Add Repair
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ calculateTotalPrice() }}</div>
        </v-form>
      </v-card-text>
      <v-btn color="primary" @click="showForm = false">Cansel</v-btn>
      <v-btn color="success" @click="submitNewRepair">New Repair</v-btn>
      <v-btn color="success" @click="submitNewRepairAndPrint">New Repair & Print</v-btn>
    </v-card>

    <v-card v-if="showForm && isEditMode">
      <v-card-title class="headline">Edit Repair</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
               <v-text-field v-model="repair.passcode" label="Passcode" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="repair.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in repair.repairs" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Repair Detail" outlined dense @input="updateRepairDetail(index, $event, item.price)"></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense @input="updateRepairDetail(index, item.detail, $event.target.value)"></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addDetailRepair" v-if="repair.repairs.length < 3">
            Add Repair
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ totalPrice }}</div>
        </v-form>
      </v-card-text>
      <v-btn color="primary" @click="submitEditRepair">Edit Repair</v-btn>
      <v-btn color="primary" @click="showForm = false">Cansel</v-btn>
    </v-card>

    <!-- <v-card v-if="showForm">
      <v-card-title class="headline">Repair</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
               <v-text-field v-model="repair.passcode" label="Passcode" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="repair.phone" label="Phone No" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in repair.repairs" :key="index">
            <v-col cols="12">
              <v-textarea v-model="item.detail" label="Repair Detail" outlined dense @input="updateRepairDetail(index, item.detail, item.price)"></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="item.price" label="Price (RM)" type="number" outlined dense @input="updateRepairDetail(index, item.detail, item.price)"></v-text-field>
            </v-col>
          </v-row>
          <v-btn text color="primary" @click="addRepair" v-if="repair.repairs.length < 3">
            Add Repair
          </v-btn>
          <v-spacer></v-spacer>
          <div>Total Price (RM): {{ calculateTotalPrice() }}</div>
          <v-btn color="success" @click="generatePDF" class="white--text" v-if="!isEditMode">
            Generate PDF
          </v-btn>
          <v-btn color="primary" @click="updateRepair" v-if="isEditMode">
            Update Repair
          </v-btn>
          <v-btn color="primary" @click="showForm = false">
            Cansel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card> -->
  </v-container>
</template>
  
  <script>
  import { jsPDF } from 'jspdf';
  export default {
    data() {
      return {
        isEditMode: false, // 默认为添加模式
        showForm: false, // 控制表单显示的变量
        repairs: [],
        repair: {
          passcode: '',
          phone: '',
          repairs: [
            { detail: '', price: '' }, // 初始化时包含一个空的修理项目
          ],
          updated_at:'',
          price: '',
        }
      };
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    },
    mounted() {
      this.fetchRepairsData();
    },
    computed: {
      
      sortedRepairs() {
        // 首先过滤出状态为 true 的维修记录
        const filteredRepairs = this.repairs.filter(repair => Number(repair.status) === 1);

        // 然后对这些记录进行排序
        return filteredRepairs.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      },
      totalPrice() {
        return this.repair.repairs.reduce((total, item) => {
          return total + Number(item.price);
        }, 0);
      },
    },
    methods: {
      reprint(repair) {
        this.repair = Object.assign({}, repair);
        // 假设 generatePDF 方法需要一个 repair 对象作为参数
        this.generatePDF(repair);
      },
      submitRepairAndPrint(repair) {
        this.submitNewRepair(repair).then(() => {
          this.generatePDF(repair);
        }).catch(error => {
          console.error('Failed to create new repair and print:', error);
        });
      },

      markAsCompleted(repair) {
        // Set the status to false to indicate completion
        const updatedStatus = { status: 2 };

        // Send the updated status to the server
        axios.put(`/api/repair/${repair.id}`, updatedStatus)
          .then(response => {
            this.fetchRepairsData();
            // Remove the repair from the list if the update is successful
            // this.repairs = this.repairs.filter(item => item.id !== repair.id);
          })
          .catch(error => {
            // Handle any errors
            console.error('Error updating repair status:', error);
          });
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
      },

      formatTime(dateString) {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateString);
        return date.toLocaleTimeString('en-US', options);
      },
      showFormMethod() {
        this.resetForm();
        this.showForm = true; // 这将隐藏表单
      },
      closeForm() {
        this.showForm = false; // 这将隐藏表单
        this.isEditMode = false; // 这将隐藏表单
        this.resetForm();
      },
      fetchRepairsData() {
      axios.get('/api/repair')
        .then(response => {
          this.repairs = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    addDetailRepair() {
      // 添加一个新的维修详情对象到数组中
      this.repair.repairs.push({ detail: '', price: '' });
    },
    addRepair() {
      this.repair.repairs.push({ detail: '', price: '' });
      this.updateTotalPrice(); // 更新总价
      this.resetForm();
    },
    updateRepairDetail(index, newDetail, price) {
      this.$set(this.repair.repairs, index, { detail: newDetail, price: price });
      this.updateTotalPrice(); // 更新总价
    },
    editItem(repair) {
      // 设置表单数据为选中的维修记录
      this.resetForm();
      this.repair = Object.assign({}, repair);
      this.isEditMode = true; // 进入编辑模式
      this.showForm = true; // 显示表单
    },
    // updateRepairDetail(index, newDetail, price) {
    //   console.log(newDetail); // Log the new detail to see what's being passed
    //   this.$set(this.repair.repairs, index, { detail: newDetail, price: price });
    // },
    submitNewRepair() {
      const postData = {
        passcode: this.repair.passcode,
        phone: this.repair.phone,
        repairs: this.repair.repairs,
        totalprice: this.calculateTotalPrice()
      };

      // 发送POST请求到API
      return axios.post('/api/repair', postData)
      .then(response => {
        // Handle success
        this.fetchRepairsData();
        this.closeForm();
        console.log(response.data);
        return response; // Ensure a response is returned
      })
      .catch(error => {
        // Handle error
        console.error(error);
        throw error; // Re-throw the error to be caught by the caller
      });
    },
    submitNewRepairAndPrint() {
      const repair = {
        passcode: this.repair.passcode,
        phone: this.repair.phone,
        repairs: this.repair.repairs,
        totalprice: this.calculateTotalPrice()
      };

      // 发送POST请求到API
      return axios.post('/api/repair', repair)
      .then(response => {
        // Handle success
        const repair = response.data;
        this.generatePDF(repair);
        this.closeForm();
        console.log(response.data);
        this.fetchRepairsData();
         // Ensure a response is returned
        
        return response;
      })
      .catch(error => {
        // Handle error
        console.error(error);
        throw error; // Re-throw the error to be caught by the caller
      });
    },

    // 编辑维修记录的逻辑
    submitEditRepair() {
      // 构建要发送的数据
      this.repair.totalprice = this.calculateTotalPrice();

      // Now, construct the updatedData object with the updated totalPrice
      const updatedData = {
        passcode: this.repair.passcode,
        phone: this.repair.phone,
        repairs: this.repair.repairs,
        totalprice: this.repair.totalprice // Ensure this is included and updated
      };

      // 发送 PUT 请求到服务器以更新维修记录
      axios.put(`/api/repair/${this.repair.id}`, updatedData)
        .then(response => {
          // 更新成功后的逻辑
          console.log(this.repair.repairs); 
          this.fetchRepairsData(); // 重新获取最新的维修记录列表
          this.showForm = false; // 隐藏表单
          this.isEditMode = false; // 退出编辑模式
        })
        .catch(error => {
          // 错误处理
          console.error('Error updating repair:', error);
        });
    },
    updateTotalPrice() {
      this.repair.totalprice = this.calculateTotalPrice();
    },
    calculateTotalPrice() {
      return this.repair.repairs.reduce((total, item) => {
        return total + Number(item.price);
      }, 0);
    },
    // updateRepair() {
    //   // 构建要发送的数据
    //   const updatedData = {
    //     passcode: this.repair.passcode,
    //     phone: this.repair.phone,
    //     repairs: this.repair.repairs.map(repair => ({
    //       detail: repair.detail,
    //       price: repair.price
    //     })),
    //     totalPrice: this.calculateTotalPrice()
    //   };

    //   // 发送 PUT 请求到服务器以更新维修记录
    //   axios.put(`/api/repair/${this.repair.id}`, updatedData)
    //     .then(response => {
    //       // 更新成功后的逻辑
    //       this.fetchRepairsData(); // 重新获取最新的维修记录列表
    //       this.showForm = false; // 隐藏表单
    //       this.isEditMode = false; // 退出编辑模式
    //     })
    //     .catch(error => {
    //       // 错误处理
    //       console.error('Error updating repair:', error);
    //     });
    // },
        deleteItem(repair) {
          // 弹出确认对话框
          if (window.confirm(`Are you sure you want to delete the repair?`)) {
            // 用户确认删除，发送请求到服务器删除项
            axios.delete(`/api/repair/${repair.id}`)
              .then(() => {
                // 从本地列表移除
                this.repairs = this.repairs.filter((data) => data.id !== repair.id);
              })
              .catch(error => {
                console.error(error);
              });
          } else {
            // 用户取消删除
            console.log('Delete action cancelled by the user.');
          }
        },
        calculateTotalPrice() {
          return this.repair.repairs.reduce((total, item) => {
            return total + Number(item.price);
          }, 0);
        },
        generatePDF(repair) {
            // 创建 A5 纸张大小的 PDF，横向
            const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a5'
            });
            const pageWidth = doc.internal.pageSize.getWidth();
            const lineHeight = 10;
            let currentLine = 10; // 根据需要调整
            const margin = 10;

            // 添加公司 Logo
            // 注意：你需要将 'your-logo.png' 替换为实际的图片路径或者Base64编码的图片数据
            // doc.addImage('storage/fl.png', 'PNG', margin, currentLine, 40, 20);
            // currentLine += 30; // 根据 Logo 的大小调整

            doc.setFontSize(16);
            let text = 'Future Link Solution';
            let textWidth = doc.getTextWidth(text);
            doc.text(text, (pageWidth - textWidth) / 2, currentLine);
            currentLine += lineHeight * 0.5;

            // 添加其他信息，居中
            doc.setFontSize(10);
            let ssm = '(JM0997245-M)';
            let addressLine1 = 'East Wing, Central Park No.1,';
            let addressLine2 = 'Jalan PJU 5/7, 47810 Petaling Jaya,';
            let addressLine3 = 'Selangor, Malaysia.';
            let phoneLine = 'Phone: 014-367 3794(Hao)  016-926 6945(CY)'; // 假设的电话号码

            // 减小行高值
            let smallerLineHeight = 5; // 或者你想要的任何较小的值

            let textWidthssm = doc.getTextWidth(ssm);
            let textWidth1 = doc.getTextWidth(addressLine1);
            let textWidth2 = doc.getTextWidth(addressLine2);
            let textWidth3 = doc.getTextWidth(addressLine3);
            let textWidthPhone = doc.getTextWidth(phoneLine);

            doc.text(ssm, (pageWidth - textWidthssm) / 2, currentLine);
            currentLine += smallerLineHeight;
            doc.text(addressLine1, (pageWidth - textWidth1) / 2, currentLine);
            currentLine += smallerLineHeight;
            doc.text(addressLine2, (pageWidth - textWidth2) / 2, currentLine);
            currentLine += smallerLineHeight;
            doc.text(addressLine3, (pageWidth - textWidth3) / 2, currentLine);
            currentLine += smallerLineHeight;

            // 添加电话号码，居中
            doc.text(phoneLine, (pageWidth - textWidthPhone) / 2, currentLine);
            currentLine += lineHeight * 1; // 或者更多，根据需要调整

            doc.setFontSize(12); // 设置标题的字体大小
            doc.text('Repair Request', (pageWidth - doc.getTextWidth('Repair Request')) / 2, currentLine);
            currentLine += lineHeight; // 更新currentLine以便在标题下方留出空间

            // 添加生成的日期和时间
            doc.setFontSize(12);
            const dateString = `Date: ${this.formatDate(repair.updated_at)}`;
            const timeString = `Time: ${this.formatTime(repair.updated_at)}`;
            // 计算时间文本的宽度
            const timeTextWidth = doc.getTextWidth(timeString);

            // 添加日期和时间，确保时间在页面的右侧与日期对齐
            doc.text(dateString, margin, currentLine);
            doc.text(timeString, pageWidth - margin - timeTextWidth, currentLine);
            currentLine += lineHeight; // 移动到下一行

            // 添加模型信息在日期下面
            doc.text(`Passcode: ${this.repair.passcode}`, margin, currentLine);

            // 计算电话号码文本的宽度
            const phoneString = `Phone: ${this.repair.phone}`;
            const phoneTextWidth = doc.getTextWidth(phoneString);

            // 确保电话号码不会与修理项目框重叠
            // 你可能需要调整这个值以确保电话号码有足够的空间
            const phoneXPosition = pageWidth - margin - phoneTextWidth; // 减去额外的空间作为右边距

            // 添加电话号码，确保它在页面的右侧与模型信息对齐
            doc.text(phoneString, phoneXPosition, currentLine);

            // 更新currentLine以避免与修理项目框重叠
            currentLine += lineHeight * 0.5;

            // /// 遍// 计算整个修理项目框的高度
            // const boxHeight = lineHeight * 1.9 * this.repair.repairs.length; // 每个项目两行，乘以项目数

            // // 绘制包含所有修理项目的大框
            // doc.rect(margin, currentLine, pageWidth - margin * 2, boxHeight);

            // // 遍历修理项目并添加到大框内
            // this.repair.repairs.forEach(repair => {
            //   const priceText = `Price: ${repair.price}`;
            //   const priceTextWidth = doc.getTextWidth(priceText);
            //   const paddingRight = 2; // 设置右边距为 2mm
            //   const priceXPosition = pageWidth - margin - priceTextWidth - paddingRight; // 在价格的 X 位置减去右边距

            //   // 在框内添加修理详情文本在左边
            //   doc.text(`Repair: ${repair.detail}`, margin + 2, currentLine + lineHeight);

            //   // 在框内添加价格文本在右边，带有右边距
            //   doc.text(priceText, priceXPosition, currentLine + lineHeight);

            //   // 移动到下一个项目的起始位置
            //   // 减少每个项目之间的空间，这里使用 lineHeight * 1.5 代替原来的 lineHeight * 2
            //   currentLine += lineHeight * 1.5; // 每个项目占用一行加上一些额外的空间
            // });

            let totalContentHeight = 0;
            this.repair.repairs.forEach(repair => {
              const priceText = `Price: ${repair.price}`;
              const priceTextWidth = doc.getTextWidth(priceText);
              const paddingRight = 2; // 设置右边距为 2mm
              const maxDetailWidth = pageWidth - margin * 2 - priceTextWidth - paddingRight - 10; // 重新计算最大宽度以适应价格文本
              let splitDetailText = doc.splitTextToSize(repair.detail, maxDetailWidth);
              let detailTextHeight = lineHeight * splitDetailText.length;
              totalContentHeight += detailTextHeight + 5; // 为每个项目添加额外的空间
            });

            // 绘制大框
            doc.rect(margin, currentLine, pageWidth - margin * 2, totalContentHeight);

            // 再次遍历修理项目并添加到大框内
            this.repair.repairs.forEach(repair => {
              const priceText = `Price: ${repair.price}`;
              const priceTextWidth = doc.getTextWidth(priceText);
              const paddingRight = 2;
              const priceXPosition = pageWidth - margin - priceTextWidth - paddingRight;
              const maxDetailWidth = priceXPosition - margin - 10;

              let splitDetailText = doc.splitTextToSize(repair.detail, maxDetailWidth);
              splitDetailText.forEach((line, index) => {
                // 绘制详情文本
                doc.text(line, margin + 2, currentLine + lineHeight * (index + 1));
              });

              // 绘制价格文本，确保它在详情文本旁边
              doc.text(priceText, priceXPosition, currentLine + lineHeight);

              let detailTextHeight = lineHeight * splitDetailText.length;
              // 更新 currentLine，为下一个项目留出空间
              currentLine += detailTextHeight + 5; // 添加额外的空间作为项目间隔
            });

            // 移动到大框的下面
            currentLine += lineHeight * 1.5;

            // 显示总价
            const totalprice = this.calculateTotalPrice();
            doc.text(`Total Price (RM): ${totalprice}`, margin, currentLine);

            // // 在总价下面增加一些空间，然后再添加签名行
            // currentLine += lineHeight * 3; // 根据需要调整空间大小

            // // 添加签名行
            // const signatureWidth = 60; // 签名线的宽度
            // const signatureSpacing = 5; // 签名线与文本之间的空间
            // doc.setFontSize(10);

            // // 计算文本的起始位置，这样线会在文本上方
            // const signatureTextYPosition = currentLine + signatureSpacing;

            // // 客户签名在左边
            // doc.line(margin, currentLine, margin + signatureWidth, currentLine); // 签名线
            // doc.text('Customer Signature', margin, signatureTextYPosition); // 文本在线下方

            // // 授权签名在右边
            // const rightSignatureMargin = pageWidth - margin - signatureWidth; // 计算右边签名的起始位置
            // doc.line(rightSignatureMargin, currentLine, pageWidth - margin, currentLine); // 签名线
            // doc.text('Authorized Signature', rightSignatureMargin, signatureTextYPosition); // 文本在线下方

            // // 更新 currentLine 以便在签名区域下方留出空间
            // currentLine += signatureSpacing + lineHeight;

            // 添加页脚
            doc.setFontSize(7);
            let footerText = 'Thank you for your business support!';
            let footerTextWidth = doc.getTextWidth(footerText);
            doc.text(footerText, (pageWidth - footerTextWidth) / 2, doc.internal.pageSize.height - 10);

            // 生成 PDF 并打开打印对话框
            const pdfOutput = doc.output('blob');
            const pdfUrl = URL.createObjectURL(pdfOutput);
            const printWindow = window.open(pdfUrl);
            printWindow.onload = function() {
            printWindow.focus();
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
            };
            // // 准备要发送的数据
            // const postData = {
            //   passcode: this.repair.passcode,
            //   phone: this.repair.phone,
            //   repairs: this.repair.repairs,
            //   totalPrice: this.calculateTotalPrice()
            // };

            // // 发送POST请求到API
            // axios.post('/api/repair', postData)
            //   .then(response => {
            //     // 请求成功处理
            //     this.fetchRepairsData();
            //     this.closeForm();
            //     console.log(response.data);
            //   })
            //   .catch(error => {
            //     // 请求失败处理
            //     console.error(error);
            //   });
        },
      resetForm() {
        // 重置 repair 对象
        this.repair = {
          passcode: '',
          phone: '',
          repairs: [{ detail: '', price: '' }],
        };
      },
    }
  };
  </script>
  <style scoped>
  .repair-item {

    word-wrap: break-word; /* 在长单词或URL内部进行换行 */

  }
  </style>